import React from "react";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faEnvelope } from "@fortawesome/free-solid-svg-icons";
import {
  faGithub,
  faLinkedin,
  faInstagram,
  faMedium,
} from "@fortawesome/free-brands-svg-icons";

import INFO from "../../data/user";

import "./styles/socials.css";

const Socials = () => {
  return (
    <div className="socials-container">
      <div className="social">
        <a href={INFO.socials.github} target="_blank" rel="noreferrer">
          <div className="social-icon">
            <FontAwesomeIcon icon={faGithub} className="social-icon" />
          </div>
          <div className="social-text">Follow on GitHub</div>
        </a>
      </div>

      <div className="social">
        <a href={INFO.socials.linkedin} target="_blank" rel="noreferrer">
          <div className="social-icon">
            <FontAwesomeIcon icon={faLinkedin} className="social-icon" />
          </div>
          <div className="social-text">Follow on LinkedIn</div>
        </a>
      </div>

    

      <div className="social">
        <a href={INFO.socials.medium} target="_blank" rel="noreferrer">
          <div className="social-icon">
            <FontAwesomeIcon icon={faMedium} className="social-icon" />
          </div>
          <div className="social-text">Read my blogs</div>
        </a>
      </div>


      <div className="email">
        <div className="email-wrapper">
          <a href={`mailto:${INFO.main.email}`} target="_blank" rel="noreferrer">
            <div className="social-icon">
              <FontAwesomeIcon icon={faEnvelope} />
            </div>
            <div className="social-text">{INFO.main.email}</div>

          </a>
        </div>
      </div>
    </div>
  );
};

export default Socials;
